var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relevant-policy"},[_vm._l((_vm.listData),function(items){return _c('div',{key:items.oaFormaleid,staticClass:"policy-item",on:{"click":function($event){return _vm.goToDetail(items.zhengceid, items.oaFormaleid)}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(items.subtitle))]),_c('div',{staticClass:"tags"},[_vm._l((items.supportMode),function(itemtag,i2){return _c('div',{key:i2,staticClass:"tag-item",class:{
            fundSupport: itemtag == '资金扶持',
            equityInvestment: itemtag == '股权投资',
            titleRecognition: itemtag == '称号认定',
            taxAndFeeReductions: itemtag == '减税降费',
            financialSupport: itemtag == '金融扶持',
            others: itemtag == '其他'
          }},[_vm._v(" "+_vm._s(itemtag)+" ")])}),(items.money)?_c('div',{staticClass:"money-tag"},[_vm._v(" "+_vm._s(items.money)+" ")]):_vm._e()],2),_c('div',{staticClass:"formtime"},[_c('span',{staticClass:"from"},[_vm._v(_vm._s(items.fwDepartment))]),_c('span',{staticClass:"time",class:{ red: items.RemainingTime !== -1 }},[_vm._v(" "+_vm._s(items.RemainingTime == -1 ? "已截止" : items.RemainingTime == 0 ? "今天截止" : "还剩" + items.RemainingTime + "天")+" ")])])])])}),(_vm.dialogVisiblePermission)?_c('permission',{attrs:{"dialogVisiblePermission":_vm.dialogVisiblePermission,"typeDialog":_vm.typeDialog},on:{"handleToggle":_vm.handleToggle,"handlePermission":_vm.handlePermission}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }