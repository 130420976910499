<template>
  <!--相关政策-->
  <div class="relevant-policy">
    <div
      class="policy-item"
      v-for="items in listData"
      :key="items.oaFormaleid"
      @click="goToDetail(items.zhengceid, items.oaFormaleid)"
    >
      <div class="content">
        <!--      标题-->
        <div class="title">{{ items.subtitle }}</div>
        <!--      标签-->
        <div class="tags">
          <div
            class="tag-item"
            v-for="(itemtag, i2) in items.supportMode"
            :key="i2"
            :class="{
              fundSupport: itemtag == '资金扶持',
              equityInvestment: itemtag == '股权投资',
              titleRecognition: itemtag == '称号认定',
              taxAndFeeReductions: itemtag == '减税降费',
              financialSupport: itemtag == '金融扶持',
              others: itemtag == '其他'
            }"
          >
            {{ itemtag }}
          </div>
          <div class="money-tag" v-if="items.money">
            {{ items.money }}
          </div>
        </div>
        <!--      发布方与剩余时间-->
        <div class="formtime">
          <span class="from">{{ items.fwDepartment }}</span>
          <span class="time" :class="{ red: items.RemainingTime !== -1 }">
            {{
              items.RemainingTime == -1
                ? "已截止" 
                : items.RemainingTime == 0
                ? "今天截止"
                : "还剩" + items.RemainingTime + "天"
            }}
          </span>
        </div>
      </div>
    </div>
    <permission
      v-if="dialogVisiblePermission"
      :dialogVisiblePermission="dialogVisiblePermission"
      @handleToggle="handleToggle"
      :typeDialog="typeDialog"
      @handlePermission="handlePermission"
    >
    </permission>
  </div>
</template>

<script>
import {
  // getVip,
  setInfoData,
} from "@/assets/public/utils/token";
import permission from "@/components/pc/permission.vue";
import { request } from "@/network";
export default {
  name: "policyListComp",
  components: {
    permission,
  },
  data() {
    return {
      listData: [],
      topicDetailList: [],
      dialogVisiblePermission: false,
      typeDialog: "Vip",
    };
  },
  props: {
    getTopicDetail: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToDetail(id, inputId) {
      setInfoData(request).then((res) => {
        if (res === "islogin") {
          this.$router.push({
            path: "/formaldetails/jiedu",
            query: {
              id: id,
              inputId: inputId,
            },
          });
        } else {
          this.dialogVisiblePermission = true;
          this.typeDialog = "Login";
        }
      });
    },
    handleToggle() {
      this.dialogVisiblePermission = false;
    },
    handlePermission(type) {
      if (type === "Login") {
        this.$router.push({ name: "login" });
        this.dialogVisiblePermission = false;
      }
    },
  },
  watch: {
    getTopicDetail(newVal) {
      this.listData = newVal;
    },
  },
};
</script>

<style scoped>
.relevant-policy {
  height: 520px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* align-content: space-between; */
  align-content: flex-start;
  background-color: #fff;
}
.policy-item {
  /*padding: 24px 17px 22px 18px;*/
  /*margin-left: 18px;*/
  width: 350px;
  margin: 5px 75px 5px 0;
  box-sizing: border-box;
  position: relative;
  width: 350px;
  height: 160px;
  border: 1px solid #d4d6da;
  border-radius: 5px;
  cursor: pointer;
}
.policy-item:nth-child(3n){
  margin-right: 0;
}
.policy-item .content {
  margin: 24px 17px 22px 18px;
}
.policy-item .content .title {
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
}
.policy-item .content .tags {
  display: flex;
  position: absolute;
  top: 78px;
  font-size: 14px;
}
.policy-item .content .tags .tag-item {
  padding: 6px 8px 6px 6px;
  border-radius: 3px;
  margin-right: 10px;
}
.policy-item .content .tags .money-tag {
  padding: 6px 8px 6px 6px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  color: #8d8d8d;
}
.formtime {
  position: absolute;
  top: 126px;
  display: flex;
  justify-content: space-between;
  width: 315px;
  font-size: 14px;
}
.formtime .from {
  color: #8d8d8d;
}
/*标签背景颜色样式*/
.fundSupport {
  color: #ffffff;
  background-color: #f68621;
}
.equityInvestment {
  color: #ffffff;
  background-color: #1f88f9;
}
.titleRecognition {
  color: #ffffff;
  background-color: #2ebc66;
}
.taxAndFeeReductions {
  color: #ffffff;
  background-color: #bf382f;
}
.financialSupport {
  color: #ffffff;
  background-color: #6cc2ed;
}
.others {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
}
/*剩余天数样式*/
.red {
  color: red;
}
</style>
